<template>
  <div class="yusu-top10-detail">
    <section class="yusu-top10-detail-main">
      <div class="yusu-top10-detail-left">
        <swiper
          class="yusu-top-detail-left-swiper"
          :slides-per-view="3"
          :space-between="50"
          :pagination="{ clickable: true }"
          :options="swiperOptionPro"
        >
          <swiper-slide
            v-for="(item, index) in curDetail.bannerImgList"
            :key="index"
          >
            <img :src="item" alt="" />
          </swiper-slide>
          <div class="swiper-button-prev" slot="button-prev"></div>
          <div class="swiper-button-next" slot="button-next"></div>
        </swiper>
        <div class="left-small-img">
          <img
            :src="item"
            alt=""
            v-for="(item, index) in curDetail.bannerImgList"
          />
        </div>
      </div>
      <div class="yusu-top10-detail-mid">
        <div class="mid-item">
          <div class="mid-lable">{{ curDetail.detail.productName }}</div>
          <div class="mid-text">{{ curDetail.detail.productNameEN }}</div>
        </div>
        <div class="mid-item">
          <div class="mid-lable">{{ curDetail.detail.effectLabel }}</div>
          <div class="mid-text" v-html="curDetail.detail.effect"></div>
          <img class="yusu-dou" :src="detailDou" alt="" />
        </div>
        <div class="mid-item">
          <div class="mid-lable">
            {{ curDetail.detail.coreIngredientsLabel }}
          </div>
          <div class="mid-text" v-html="curDetail.detail.coreIngredients"></div>
          <img class="yusu-dou" :src="detailDou" alt="" />
        </div>
        <div class="mid-item">
          <div class="mid-lable">
            {{ curDetail.detail.suitableForSkinLabel }}
          </div>
          <div class="mid-text" v-html="curDetail.detail.suitableForSkin"></div>
          <img class="yusu-dou" :src="detailDou" alt="" />
        </div>
        <div class="mid-item">
          <div class="mid-lable">{{ curDetail.detail.useLable }}</div>
          <div class="mid-text" v-html="curDetail.detail.use"></div>
          <img class="yusu-dou" :src="detailDou" alt="" />
        </div>
        <div class="mid-item">
          <span class="mid-lable">{{ curDetail.detail.netWeightLabel }}</span>
          <span class="mid-text" v-html="curDetail.detail.netWeight"></span>
        </div>
      </div>
      <div class="yusu-top10-detail-right">
        <div class="right-title">你可能还喜欢：</div>
        <div
          class="like-product"
          v-for="(item, index) in curDetail.likes"
          :key="index"
        >
          <img :src="item.url" alt="" @click="goToProduct(item.num)" />
        </div>
      </div>
    </section>
    <section class="yusu-top10-detail-show-img">
      <img :src="curDetail.bottomImg" alt="" />
    </section>
  </div>
</template>

<script>
import detailBg from '../../assets/images/top10/detail-bg.png'
import detailDou from '../../assets/images/top10/dou.png'
/*********************************详情页的图片*************************/
//  可能会喜欢的图
import imgRecommend01 from '../../assets/images/top10/recommend-product/like-01.png'
import imgRecommend02 from '../../assets/images/top10/recommend-product/like-02.png'
import imgRecommend03 from '../../assets/images/top10/recommend-product/like-03.png'
import imgRecommend04 from '../../assets/images/top10/recommend-product/like-04.png'
import imgRecommend05 from '../../assets/images/top10/recommend-product/like-05.png'
import imgRecommend06 from '../../assets/images/top10/recommend-product/like-06.png'
import imgRecommend07 from '../../assets/images/top10/recommend-product/like-07.png'
import imgRecommend08 from '../../assets/images/top10/recommend-product/like-08.png'
import imgRecommend09 from '../../assets/images/top10/recommend-product/like-09.png'
import imgRecommend10 from '../../assets/images/top10/recommend-product/like-10.png'

// top-01 banner 图和bottom图
import top01Banner01 from '../../assets/images/top10/top01/detail/top-01-banner-01.png'
import top01Banner02 from '../../assets/images/top10/top01/detail/top-01-banner-02.png'
import top01Bottom from '../../assets/images/top10/top01/detail/top-01-bottom.png'

// top-02 banner 图和bottom图
import top02Banner01 from '../../assets/images/top10/top02/detail/top-02-banner-01.png'
import top02Banner02 from '../../assets/images/top10/top02/detail/top-02-banner-02.png'
import top02Banner01Small from '../../assets/images/top10/top02/detail/top-02-banner-01-small.png'
import top02Banner02Small from '../../assets/images/top10/top02/detail/top-02-banner-02-small.png'
import top02Bottom from '../../assets/images/top10/top02/detail/top-02-bottom.png'

// top-03 banner 图和bottom图
import top03Banner01 from '../../assets/images/top10/top03/detail/top-03-banner-01.png'
import top03Banner02 from '../../assets/images/top10/top03/detail/top-03-banner-02.png'
import top03Banner03 from '../../assets/images/top10/top03/detail/top-03-banner-03.png'
import top03Bottom from '../../assets/images/top10/top03/detail/top-03-bottom.png'

// top-04 banner 图和bottom图
import top04Banner01 from '../../assets/images/top10/top04/detail/top-04-banner-01.png'
import top04Banner02 from '../../assets/images/top10/top04/detail/top-04-banner-02.png'
import top04Banner03 from '../../assets/images/top10/top04/detail/top-04-banner-03.png'
import top04Bottom from '../../assets/images/top10/top04/detail/top-04-bottom.png'

// top-05 banner 图和bottom图
import top05Banner01 from '../../assets/images/top10/top05/detail/top-05-banner-01.png'
import top05Banner02 from '../../assets/images/top10/top05/detail/top-05-banner-02.png'
import top05Bottom from '../../assets/images/top10/top05/detail/top-05-bottom.png'

// top-06 banner 图和bottom图
import top06Banner01 from '../../assets/images/top10/top06/detail/top-06-banner-01.png'
import top06Banner02 from '../../assets/images/top10/top06/detail/top-06-banner-02.png'
import top06Bottom from '../../assets/images/top10/top06/detail/top-06-bottom.png'

// top-07 banner 图和bottom图
import top07Banner01 from '../../assets/images/top10/top07/detail/top-07-banner-01.png'
import top07Banner02 from '../../assets/images/top10/top07/detail/top-07-banner-02.png'
import top07Bottom from '../../assets/images/top10/top07/detail/top-07-bottom.png'

// top-08 banner 图和bottom图
import top08Banner01 from '../../assets/images/top10/top08/detail/top-08-banner-01.png'
import top08Banner02 from '../../assets/images/top10/top08/detail/top-08-banner-02.png'
import top08Bottom from '../../assets/images/top10/top08/detail/top-08-bottom.png'

// top-09 banner 图和bottom图
import top09Banner01 from '../../assets/images/top10/top09/detail/top-09-banner-01.png'
import top09Banner02 from '../../assets/images/top10/top09/detail/top-09-banner-02.png'
import top09Bottom from '../../assets/images/top10/top09/detail/top-09-bottom.png'

// top-10 banner 图和bottom图
import top10Banner01 from '../../assets/images/top10/top10/detail/top-10-banner-01.png'
import top10Banner02 from '../../assets/images/top10/top10/detail/top-10-banner-02.png'
import top10Bottom from '../../assets/images/top10/top10/detail/top-10-bottom.png'

import { Swiper, SwiperSlide, directive } from 'vue-awesome-swiper'
export default {
  name: 'popular-top10-detail',
  components: {
    Swiper,
    SwiperSlide,
  },
  directives: {
    swiper: directive,
  },
  props: {},
  data: () => {
    return {
      curDetail: {},
      detailDou: detailDou,
      productList: [
        {
          bottomImg: top01Bottom,
          bannerImgList: [top01Banner01, top01Banner02],
          bannerImgSmallList: [top02Banner01Small, top02Banner02Small],
          likes: [
            {
              url: imgRecommend05,
              num: 5,
            },
            {
              url: imgRecommend08,
              num: 8,
            },
          ],
          detail: {
            productName: '羽素胶态硫祛痘精华液(进阶型)',
            productNameEN: 'Colloidal Sulfur Anti-Acne Essence (Advanced)',
            effectLabel: '护肤功效',
            effect: '改善痘痘痘印，舒缓痘肌，加速痘痘成熟',
            coreIngredientsLabel: '核心成分',
            coreIngredients: '胶态硫、皮傲宁、甘草酸二钾、白薇',
            suitableForSkinLabel: '适合肌肤',
            suitableForSkin: '痘痘肌肤适用',
            useLable: '如何使用',
            use: '洁面后使用，取适量本品均匀涂抹于/点涂于痘痘部位,建议痘痘部位一天使用2-3次，每次使用前请清洁痘肌部位',
            netWeightLabel: '净含量：',
            netWeight: '15ml',
          },
        },
        {
          bottomImg: top02Bottom,
          bannerImgList: [top02Banner01, top02Banner02],
          bannerImgSmallList: [top02Banner01Small, top02Banner02Small],
          likes: [
            {
              url: imgRecommend07,
              num: 5,
            },
            {
              url: imgRecommend10,
              num: 10,
            },
          ],
          detail: {
            productName: '纤连蛋白祛痘精华液（修护型）',
            productNameEN: 'Fibronectin Anti-Acne essence（Repairing）',
            effectLabel: '护肤功效',
            effect: '修护受损肌肤、减少痘痘复发',
            coreIngredientsLabel: '核心成分',
            coreIngredients:
              '3%高含量纤连蛋白</br>马达加斯加积雪草、法国番红花提取物、韩国进口白薇',
            suitableForSkinLabel: '适合肌肤',
            suitableForSkin: '适用肌肤：各种肌肤适用，尤其是敏感痘痘肌',
            useLable: '如何使用',
            use: '点涂在痘痘患处，打圈至吸收即可',
            netWeightLabel: '净含量：',
            netWeight: '15ml',
          },
        },
        {
          bottomImg: top03Bottom,
          bannerImgList: [top03Banner01, top03Banner02, top03Banner03],
          bannerImgSmallList: [top02Banner01Small, top02Banner02Small],
          likes: [
            {
              url: imgRecommend06,
              num: 6,
            },
            {
              url: imgRecommend01,
              num: 1,
            },
          ],
          detail: {
            productName: '羽素寡肽修护套装',
            productNameEN: 'Oligopeptide Reviving Set',
            effectLabel: '护肤功效',
            effect: '改善痘痘，淡化痘印，修护受损肌肤',
            coreIngredientsLabel: '核心成分',
            coreIngredients: '寡肽-1、神经酰胺、林兰润露、水前寺紫菜多糖',
            suitableForSkinLabel: '适合肌肤',
            suitableForSkin: '多种肌肤适用',
            useLable: '如何使用',
            use: '泵出适量精华水到固体精华粉上，固体精华粉在精华水的作用下溶解成液体，将溶解液均匀涂抹于脸部然后轻轻按摩至吸收',
            netWeightLabel: '净含量：',
            netWeight: '5.20ml+0.024g*10片',
          },
        },
        {
          bottomImg: top04Bottom,
          bannerImgList: [top04Banner01, top04Banner02, top04Banner03],
          bannerImgSmallList: [top02Banner01Small, top02Banner02Small],
          likes: [
            {
              url: imgRecommend06,
              num: 6,
            },
            {
              url: imgRecommend09,
              num: 9,
            },
          ],
          detail: {
            productName: '羽素蓝铜胜肽安肤原液套装',
            productNameEN: 'GHK-Cu Calming Set',
            effectLabel: '护肤功效',
            effect: '修护敏感，淡化痘印，舒缓肌肤干燥、粗糙、无光泽等问题',
            coreIngredientsLabel: '核心成分',
            coreIngredients: '蓝铜胜肽、纳米铂金、纳米糖原',
            suitableForSkinLabel: '适合肌肤',
            suitableForSkin: '多种肌肤适用',
            useLable: '如何使用',
            use: '使用前，将蓝铜胜肽精华液倒入原液瓶中，轻轻摇晃10秒使其完全融合并静置1-2分钟。使用时，在洁面爽肤后，取适量混合液均匀涂抹于面部肌肤，轻揉按摩至吸收',
            netWeightLabel: '净含量：',
            netWeight: '5.20ml+0.024g*10片',
          },
        },
        {
          bottomImg: top05Bottom,
          bannerImgList: [top05Banner01, top05Banner02],
          bannerImgSmallList: [top02Banner01Small, top02Banner02Small],
          likes: [
            {
              url: imgRecommend08,
              num: 8,
            },
            {
              url: imgRecommend10,
              num: 10,
            },
          ],
          detail: {
            productName: '羽素舒缓修护喷雾',
            productNameEN: 'Soothing Repair Spray',
            effectLabel: '护肤功效',
            effect: '补水、舒缓，修护，令肌肤水润嫩滑',
            coreIngredientsLabel: '核心成分',
            coreIngredients: '维敏休、德敏舒、乳酸杆菌发酵产物、积雪草提取物',
            suitableForSkinLabel: '适合肌肤',
            suitableForSkin: '多种肌肤类型，尤其适合脆弱肌肤',
            useLable: '如何使用',
            use: '对面部进行喷洒冲洗，冲洗后用洁面巾吸干再使用后续护肤品',
            netWeightLabel: '净含量：',
            netWeight: '100ml',
          },
        },
        {
          bottomImg: top06Bottom,
          bannerImgList: [top06Banner01, top06Banner02],
          bannerImgSmallList: [top02Banner01Small, top02Banner02Small],
          likes: [
            {
              url: imgRecommend01,
              num: 1,
            },
            {
              url: imgRecommend07,
              num: 7,
            },
          ],
          detail: {
            productName: '羽素舒缓修护清洁液',
            productNameEN: 'Restorative Clreanser',
            effectLabel: '护肤功效',
            effect: '温和净颜、光滑肌肤、柔和清洁脸部多余油脂和污垢',
            coreIngredientsLabel: '核心成分',
            coreIngredients: '苦参根提取物、胀果甘草根提取物、黄芩根提取物',
            suitableForSkinLabel: '适合肌肤',
            suitableForSkin: '各种肌肤（敏感肌适用）',
            useLable: '如何使用',
            use: '对面部进行喷洒冲洗，冲洗后用洁面巾吸干再使用后续护肤品',
            netWeightLabel: '净含量：',
            netWeight: '100ml',
          },
        },
        {
          bottomImg: top07Bottom,
          bannerImgList: [top07Banner01, top07Banner02],
          bannerImgSmallList: [top02Banner01Small, top02Banner02Small],
          likes: [
            {
              url: imgRecommend02,
              num: 2,
            },
            {
              url: imgRecommend03,
              num: 3,
            },
          ],
          detail: {
            productName: '羽素葡糖苷婴肌洁面露',
            productNameEN: 'Glucoside Soothing Facial Cleanser',
            effectLabel: '护肤功效',
            effect: '温和净颜、光滑肌肤、柔和清洁脸部多余油脂和污垢',
            coreIngredientsLabel: '核心成分',
            coreIngredients:
              '8%葵基葡糖苷+4%椰油基葡糖苷</br>+7%椰油酰基苹果氨基酸钠、糖基海藻糖、乳酸杆菌',
            suitableForSkinLabel: '适合肌肤',
            suitableForSkin: '适用肌肤：各种肌肤(敏感肌适用）',
            useLable: '如何使用',
            use: '将洁面加水在手心揉搓起泡，将泡沫涂抹脸部打圈按摩2-3分钟，用温水冲洗干净',
            netWeightLabel: '净含量：',
            netWeight: '100g',
          },
        },
        {
          bottomImg: top08Bottom,
          bannerImgList: [top08Banner01, top08Banner02],
          bannerImgSmallList: [top02Banner01Small, top02Banner02Small],
          likes: [
            {
              url: imgRecommend05,
              num: 5,
            },
            {
              url: imgRecommend04,
              num: 4,
            },
          ],
          detail: {
            productName: '羽素精粹石斛舒缓凝胶',
            productNameEN: 'Refinemen Dendrobium Soothing Gel',
            effectLabel: '护肤功效',
            effect: '滴滴润养，令肌肤紧实弹润，匀净透亮',
            coreIngredientsLabel: '核心成分',
            coreIngredients: '铁皮石斛提取物、透明质酸钠',
            suitableForSkinLabel: '适合肌肤',
            suitableForSkin: '多种肌肤适用',
            useLable: '如何使用',
            use: '洁面爽肤后取适量凝胶涂抹于面部，轻揉至完全吸收',
            netWeightLabel: '净含量：',
            netWeight: '30ml',
          },
        },
        {
          bottomImg: top09Bottom,
          bannerImgList: [top09Banner01, top09Banner02],
          bannerImgSmallList: [top02Banner01Small, top02Banner02Small],
          likes: [
            {
              url: imgRecommend01,
              num: 1,
            },
            {
              url: imgRecommend08,
              num: 8,
            },
          ],
          detail: {
            productName: '羽素多效修护水凝露',
            productNameEN: 'Barrier Repair Watery Gel',
            effectLabel: '护肤功效',
            effect: '提高肌肤锁水度，修护皮肤屏障，令肌肤柔润弹嫩',
            coreIngredientsLabel: '核心成分',
            coreIngredients: '纳米铂金、纳米糖原、SymRepair®100',
            suitableForSkinLabel: '适合肌肤',
            suitableForSkin: '多种肌肤适用',
            useLable: '如何使用',
            use: '早晚使用水、精华、乳之后使用，取适量本产品均匀涂抹于脸部和颈部，轻轻拍打至吸收',
            netWeightLabel: '净含量：',
            netWeight: '30g',
          },
        },
        {
          bottomImg: top10Bottom,
          bannerImgList: [top10Banner01, top10Banner02],
          bannerImgSmallList: [top02Banner01Small, top02Banner02Small],
          likes: [
            {
              url: imgRecommend06,
              num: 6,
            },
            {
              url: imgRecommend07,
              num: 7,
            },
          ],
          detail: {
            productName: '羽素舒缓保湿嫩肤面膜',
            productNameEN: 'Soothing Moisturizing Mask',
            effectLabel: '护肤功效',
            effect: '保湿补水、舒缓维稳、平衡水油为肌肤补充水分及营养',
            coreIngredientsLabel: '核心成分',
            coreIngredients: '龙胆提取物、小球藻提取物、透明质酸钠',
            suitableForSkinLabel: '适合肌肤',
            suitableForSkin: '适用肌肤：各种肌肤',
            useLable: '如何使用',
            use: '洁面后，将面膜紧贴于脸部肌肤，静等10-15分钟后揭开再用清水洗净',
            netWeightLabel: '净含量：',
            netWeight: '28mlX6片',
          },
        },
      ],
      swiperOptionPro: {
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
        autoplay: {
          delay: 6000,
        },
      },
    }
  },
  created() {
    this.curDetail = this.productList[this.$route.query.productId - 1]
    console.log(this.curDetail)
  },
  methods: {
    goToProduct(num) {
      this.curDetail = this.productList[num - 1]
    },
  },
}
</script>
<style lang="scss" scoped>
.yusu-top10-detail {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  align-items: center;
  .yusu-top10-detail-main {
    padding: 120px 0;
    background: url(../../assets/images/top10/detail-bg.png) 100% 100% no-repeat;
    background-size: 100% 100%;
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
    width: 100%;
    .yusu-top10-detail-left {
      margin: 0 20px;
      height: 426px;
      width: 426px;

      img {
        height: 100%;
      }
      .swiper-wrapper,
      .swiper-slide {
        text-align: center;
      }
      .swiper-button-prev:after,
      .swiper-container-rtl .swiper-button-next:after {
        content: url('../../assets/images/top10/arrow-left.png');
      }
      .swiper-button-prev,
      .swiper-container-rtl .swiper-button-next {
        left: 10px;
        right: auto;
      }
      .swiper-button-next,
      .swiper-container-rtl .swiper-button-prev {
        right: 10px;
        left: auto;
      }
      .swiper-button-next:after,
      .swiper-container-rtl .swiper-button-prev:after {
        content: url('../../assets/images/top10/arrow-right.png');
      }
      .yusu-top-detail-left-swiper {
        height: 100%;
        width: 100%;
        background-color: rgba(255, 255, 255, 0.3);
        padding: 15px 0;
      }
      .left-small-img {
        display: flex;
        justify-content: center;
        width: 100%;

        img {
          height: 105px;
          padding: 0 10px;
        }
      }
    }

    .yusu-top10-detail-mid {
      width: 510px;
      margin: 0 20px;
      .mid-item {
        border-bottom: 1px solid #231815;
        padding: 6px 20px;
        position: relative;
        &:last-child {
          border-bottom: 0;
        }
        .mid-lable {
          font-size: 22px;
          color: rgb(35, 24, 21);
          padding: 5px 0;
        }
        .mid-text {
          font-size: 15px;
          color: rgb(89, 87, 87);
          padding: 5px 0;
        }
        .yusu-dou {
          position: absolute;
          right: 0;
          top: 10px;
        }
      }
    }
    .yusu-top10-detail-right {
      width: 202px;
      height: 555px;
      margin: 0 20px;
      padding: 15px 0;

      .right-title {
        font-size: 18px;
        font-weight: bold;
        color: rgb(62, 58, 57);
        padding-bottom: 15%;
      }
      .like-product {
        cursor: pointer;
        padding: 10px 0;
        img {
          height: 200px;
        }
      }
      background-color: rgba(255, 255, 255, 0.3);
      text-align: center;
    }
  }
  .yusu-top10-detail-show-img {
    width: 100%;
  }
}
</style>
